import React, { useEffect, useState } from "react"
// import Footer from "../components/common/Footer"
import Header from "../components/common/NavBar"
// import MwcNewsletter from "../components/common/MwcNewsletter"
// import whatsapp from "../images/social-icons/whatsapp.svg"
// import London from "../components/common/MwcBanner"
// const London = React.lazy(() => import("../components/common/MwcBanner"))
// import * as styles from "./mainLayout.module.scss"
// import ShowcasePopup from "./ShowcasePopup"

const MainLayout = ({
  children,
  bgChanged = true,
  ppcPageHeader = true,
  schemas,
  removeFooter = false,
}) => {
  const [state, setState] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    setState(true)
  }

  const Footer =
    state && React.lazy(() => import("../components/common/Footer"))

  return (
    <React.Fragment>
      <Header bgChanged={bgChanged} ppcPageHeader={ppcPageHeader} />
      <main>
        {children}
        {/* <ShowcasePopup /> */}
        {/* <MwcNewsletter /> */}
      </main>
      {/* {!removeFooter && <Footer ppcPageHeader={ppcPageHeader} />} */}
      {state && !removeFooter && (
        <React.Suspense>
          <Footer ppcPageHeader={ppcPageHeader} />
        </React.Suspense>
      )}
      {/* <London /> */}
      {/* <a
        href="https://api.whatsapp.com/send?phone=+17867537752&text=Lets Discuss Project"
        aria-label="Contact him on WhatsApp"
        target="_blank"
        className={styles.float}
        title="Whatsapp chat"
        rel="noreferrer"
      >
        <img
          style={{ filter: "brightness(0) invert(1)", width: "25px" }}
          src={whatsapp}
          loading="eager"
          decoding="sync"
          alt="whatsapp"
        />
      </a> */}

      {schemas?.map(v => (
        <script
          defer={true}
          async={true}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: v.structuredData,
          }}
        />
      ))}
    </React.Fragment>
  )
}

export default MainLayout
